import { Component, OnInit,Inject } from '@angular/core';
import { Http_client_Service } from '../../Service/http.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { _order_master ,_order_slave , User_info  } from './model';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import {MatDialog , MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { NgxPermissionsService } from 'ngx-permissions';
import { browserRefresh } from '../../app.component';

@Component({
  selector: 'app-view-bills',
  templateUrl: './view.html',
  styleUrls: ['./view.css']
})
export class ViewActive_orderComponent implements OnInit {



  view : boolean = false;
  clicked : number = 0;
  show_hide : boolean = false;
  show_hide_hold : boolean = false;
  order_display_modal_ : any;
  order_display_modal_hold : any;
  order_display_modal_rej : any;
  order_display_modal_rej_view : any;
  loading = false;
  loading_2 = false;
  loading_3 = false;
  loading_4 = false;
  user_role: any = '';
  user_id : any ;
  status_flag : any;
  browserRefresh : boolean = false;

  rej_reason : string = '';
  orders : _order_master[] = [];
  hold_orders : _order_master[] = [];
  selected_row : _order_master = {
    ID: 18,
    ORDER_DATE: "2021-01-20",
    SHOP_ID: 7,
    SHOP_LOCATION: "Adoor",
    SHOP_NAME: "Kalyan",
    STATUS_FLAG: 1,
   };

   selected_order : _order_slave[] =[];
   user_info = new User_info(0,'',0);

   public configuration: Config;
   public columns: Columns[];
   public data = [{
    DESPATCH_ADMIN_ID: 7,
    ID: 57,
    ORDER_DATE: "2021-01-21",
    ORDER_PERSON_ID: 7,
    REMARK: "rejected man",
    SHOP_ID: 7,
    SHOP_LOCATION: "Adoor",
    SHOP_NAME: "Kalyan",
    STATUS_FLAG: 1,
    createdAt: "2021-01-29T10:15:44.000Z",
    updatedAt: "2021-01-29T11:45:15.000Z"

  }];



  constructor(public hs : Http_client_Service,private formBuilder: FormBuilder, private rs: Router,public dialog: MatDialog,private ps: NgxPermissionsService
      ) {
        this.configuration = { ...DefaultConfig };
        this.configuration.searchEnabled = true;
        this.columns = [
          { key: 'ORDER_DATE', title: 'Order Date' },
          { key: 'ID', title: 'Order No' },
          { key: 'ORDER_PERSON_ID', title: 'Order Person No' },
          { key: 'SHOP_NAME', title: 'Shop Name' },
          { key: 'SHOP_LOCATION', title: 'Location' },
        ];

   }



   ngOnInit(): void {

    // this.browserRefresh = browserRefresh;

    // console.log('br',this.browserRefresh)

    // if(this.browserRefresh == true)

    // {
      this.user_role  =   localStorage.getItem('user_role');
      this.user_id  =  localStorage.getItem('user_id');
      this.status_flag  =   localStorage.getItem('status_flag');
      this.hs.user_role = this.user_role;
      this.hs.user_id = this.user_id;
      this.hs.status_flag = this.status_flag;

    this.configuration = { ...DefaultConfig };
    this.configuration.searchEnabled = true;
    // ... etc.
    this.columns = [
      { key: 'ORDER_DATE', title: 'Order Date' },
      { key: 'ID', title: 'Order No' },
      { key: 'ORDER_PERSON_ID', title: 'Order Person No' },
      { key: 'SHOP_NAME', title: 'Shop Name' },
      { key: 'SHOP_LOCATION', title: 'Location' },
    ];


    this.order_display_modal_ =  document.getElementById("order_detail_modal");
    this.order_display_modal_hold =   document.getElementById("order_detail_modal_hold");
    this.order_display_modal_rej =   document.getElementById("order_detail_modal_rej")
    this.order_display_modal_rej_view  =   document.getElementById("order_detail_modal_rej_view")

      this.get_all_active_order_list();


  }


  modal_close_order_details()
  {
    this.order_display_modal_.style.display = "none";
  }
  modal_close_order_details_hold()
  {
    this.order_display_modal_hold.style.display = "none";
  }



  get_all_active_order_list()
  {

    this.user_info.user_role = this.user_role;
    this.user_info.user_id = this.user_id;
    this.user_info.status_flag = this.status_flag;


    this.hs.get_all_order_list(this.user_info)
      .subscribe((jsonData) => { this._get_bills(jsonData)
              },(err) => console.error(err),

              );
  }



_get_bills(json :any)
{

this.view = true;

this.data = json;

console.log('Active orders',this.data)


this.show_hide = true;
}

editItem_hold(index:any)
{

}


get_order_detail(id:number)
{
  this.hs.get_order_detail_by_id(id)
  .subscribe((jsonData) => { this._get_details_res(jsonData)
          },(err) => console.error(err),

          );
}


_get_details_res(resp:any)
{
  console.log('rowss', resp);

  this.selected_order = resp;
  this.hs.selected_order = resp;
  this.openDialog();

}




reject_new_order_b()
{
  this.order_display_modal_rej.style.display = "block";

}





update_and_close_remarks()
{
   this.order_display_modal_rej.style.display = "none";
}

modal_close_rej()
{
  this.order_display_modal_rej.style.display = "none";
}





eventEmitted(ev:any)
{
  console.log('ev',ev);
  if(ev.event != "onPagination")
  {


    if(ev.event == "onClick")
    {
      this.hs.rej_reason = ev.value.row.REMARK;
      this.hs.order_id = ev.value.row.ID;
      this.get_order_detail(ev.value.row.ID);
      this.get_order_person_detail(ev.value.row.ORDER_PERSON_ID)
      this.rej_reason = ev.value.row.REMARK;

    }


  }
}

get_order_person_detail(id:number)
{
  this.hs.get_order_person_detail_by_id(id)
  .subscribe((jsonData) => { this._get_ops_res(jsonData)
          },(err) => console.error(err),

          );
}
_get_ops_res(res:any)
{
  console.log('res',res);
  this.hs.order_person_name = res.USER_NAME
}

modal_close_rej_view()
{

  this.order_display_modal_rej_view.style.display = "none"

}

openDialog() {
  const dialogRef = this.dialog.open(DialogContentExampleDialog);

  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
  });
}



}


@Component({
  selector: 'modal-view-order',
  templateUrl: 'modal_view.html',
})
export class DialogContentExampleDialog {

  loading = false;
  loading_2 = false;
  loading_3 = false;
  loading_4 = false;
  loading_5 = false;
  rej_reason : string = '';
  rej_flag : boolean = false;
  post_model : any ={"id":0,"rej_reason":'',user_id:0}

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public hs : Http_client_Service,
  private rs: Router,private _snackBar: MatSnackBar, public dialogRef: MatDialogRef<DialogContentExampleDialog>) {}


  exicute_new_order()
{
  this.loading = true;

  this.hs.execute_order(this.hs.order_id,this.hs.user_id)
  .subscribe((jsonData) => { this._execute_order_res_(jsonData)
          },(err) => console.error(err),

          );

}

_execute_order_res_(js:any)
{

  this.loading = false;
  this.comm_call(js);

}

comm_call(js:any)
{
  this.openSnackBar(js.msg,'')
  this.dialogRef.close();

   this.rs.navigate(['order-view-active'])

}

openSnackBar(message: string, action: string) {
  this._snackBar.open(message, action, {
    duration: 2000,
    horizontalPosition: 'center',
    verticalPosition: 'top'
  });
}


revoke_new_order()
{
  this.loading_2 = true;
  this.hs.revoke_order(this.hs.order_id,this.hs.user_id)
  .subscribe((jsonData) => {   this.revoke_new_order_res_(jsonData)
          },(err) => console.error(err),
          );
}

revoke_new_order_res_(json:any)
{
  this.loading_2 = false;
  this.comm_call(json);
}

cancel_new_order()
{
  this.loading_3 = true;
  this.hs.cancel_new_order(this.hs.order_id,this.hs.user_id)
  .subscribe((jsonData) => {   this.cancel_new_order_res_(jsonData)
          },(err) => console.error(err),
          );
}

cancel_new_order_res_(json:any)
{
  this.loading_3 = false;
  this.comm_call(json);
}


reject_new_order()
{

  this.post_model.user_id = this.hs.user_id;
  this.post_model.id = this.hs.order_id;
  this.post_model.rej_reason = this.hs.rej_reason

  this.hs.reject_order(this.post_model)
  .subscribe((jsonData) => {   this.reject_order_res_(jsonData)
          },(err) => console.error(err),
          );
}
reject_order_res_(jso:any)
{

  this.comm_call(jso);


}

reject_new_order_b()
{
  this.rej_flag = true;
}

edit_holding_order()
{
  this.rs.navigate(['order-edit'])
  this.dialogRef.close();

}

cancel_holding_order()
{

  this.hs.cancel_holding_order(this.hs.order_id)
  .subscribe((jsonData) => {   this.cancel_holding_order_res_(jsonData)
          },(err) => console.error(err),
          );

}

cancel_holding_order_res_(json:any)
{

  this.comm_call(json);

}




submit_holding_order()
{
  this.hs.submit_holding_order(this.hs.order_id)
  .subscribe((jsonData) => {   this.submit_new_order_res_(jsonData)
          },(err) => console.error(err),
          );


}

submit_new_order_res_(json:any)
{
  this.comm_call(json);
}





}
