<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="/#/dash">Home</a></li>
    <li class="breadcrumb-item"><a href="/#/dash">Order</a></li>
    <li class="breadcrumb-item active" aria-current="page">New Order</li>
  </ol>
</nav>










<form [formGroup]="order_detail_Form">

  <div class="container py-3">
    <div class="row">
      <div class="col-md-12">

  <div class="card card-outline-secondary">

    <div class="card-body">
      <div class="row mt-4">





        <div  class="col-sm-3 pb-3">
          <label for="exampleAccount">Date</label>
          <input
          type="date"
          class="form-control"
          formControlName="_ORDER_DATE"
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
        />
        </div>

        <div   class="col-sm-8 pb-3">

        </div>


        <div   class="col-sm-4 pb-3">
          <label for="exampleCtrl"> Shop Search </label>


          <input  class="form-control" style="width: 100%;"


            (valueChanged)="change_shop_name($event)"
            (customSelected)="customCallback($event)"

            auto-complete
            list-formatter=" SHOP_NAME -- SHOP_LOCATION"
            loading-text="loading"
            [(ngModel)]="SHOP_SELECTOR" [ngModelOptions]="{standalone: true}"
            display-property-name="ID"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            [source]="mySource"
          />
        </div>




        <div  class="col-sm-4 pb-3">
          <label for="exampleAmount">Shop Name</label>
          <div class="input-group">

            <input
            type="text"
            class="form-control"
            formControlName="_SHOP_NAME"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"/>

          </div>
        </div>


        <div  class="col-sm-4 pb-3">
          <label for="exampleFirst">  Shop Location </label>
          <input
          type="text"
          class="form-control"
          formControlName="_SHOP_LOCATION"
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"/>
        </div>




        <div class="col-sm-6 pb-3">
          <label for="exampleLast">  Pick Product </label>


          <input class="form-control" style="width: 100%;"
             (valueChanged)="change_product_name($event)"
            (customSelected)="customCallback($event)"
            auto-complete
            list-formatter="ID -- PRODUCT_NAME --"
            loading-text="loading"
            formControlName="_PRODUCT_NAME"
            display-property-name="PRODUCT_NAME"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            [source]="pdtSource"/>


        </div>





        <div class="col-sm-3 pb-3">
          <label for="exampleSt"> Select Brand </label> <select formControlName="_BRAND"  class="form-control custom-select" id="exampleSt">
            <option class="text-white bg-warning">
              Pick a brand
            </option>


              <option *ngFor="let type of brand_list" [value]="type">{{type}}</option>


          </select>
        </div>

        <div class="col-sm-3 pb-3">
          <label for="exampleSt"> Select Size </label>
           <select  formControlName="_SIZE"  class="form-control custom-select" id="exampleSt">
            <option class="text-white bg-warning">
              Pick a size
            </option>
              <option *ngFor="let type of size_list" [value]="type">{{type}}</option>
          </select>
        </div>


        <div class="col-sm-3 pb-3">
          <label for="exampleSt"> Select Color </label> <select  formControlName="_COLOR" class="form-control custom-select" id="exampleSt">
            <option class="text-white bg-warning">
              Pick a Color
            </option>
              <option *ngFor="let type of color_list" [value]="type">{{type}}</option>
            </select>
        </div>



        <div class="col-sm-3 pb-3">
          <label for="exampleAmount">Quantiy</label>
          <div class="input-group">

            <input
            type="number"
            class="form-control"
            formControlName="_QTY"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"/>

          </div>
        </div>



        <div class="col-md-6 pb-3">
          <label for="exampleMessage">Remarks</label>
          <textarea  formControlName="_REMARK" class="form-control" id="complexExampleMessage" rows="3"></textarea>
            <small class="text-muted">Add any notes here.</small>
        </div>



      </div>
    </div>
    <div class="card-footer">
      <div class="float-right">
        <button  [disabled]="!order_detail_Form.valid"  type="button"   (click)="add_detail()"  class="btn btn-success btn-lg btn-block">
          Add To List
        </button>
      </div>
    </div>
  </div>
  </div>
  </div>
  </div>


  </form>






























<div *ngIf="show_hide && this.hs.o_m.varibs.i != -1" class="table-responsive-sm">

<div class="container">

<table  style="width: 100%;
color: #dfd9d9;
font-size: 10pt;
font-style: normal;
text-align: center;background-color:#091c29;
border-collapse: collapse;">
    <tr style="color: #030805;
    background-color: silver;
    border-bottom: 2px solid black;" >
        <th>SI</th>

        <th>item</th>
        <th>Brand</th>
        <th>Size</th>
        <th>Color</th>
        <th>Qty</th>
        <th> Edit  </th>
        <th> Delete </th>


      </tr>
    <tr *ngFor="let item of hs.o_m.items; let k=index " height="10">
      <td height="15" style="width:5%;">   {{item.SI_NO}} </td>


      <td> {{item.PRODUCT_NAME}}  </td>
      <td> {{item.BRAND}}  </td>
      <td> {{item.SIZE }} </td>
    <td> {{item.COLOR  }} </td>
    <td> {{item.QTY | number : '1.2-2'}} </td>

      <td height="8" style="width:5%;" class="tds_plus">

      <button (click)="editItem(k)" (keydown.ArrowDown)="editItem(k)"
       class="btn-outline-dark" style="background-color:#b2a7c7">
           <span class="navbar-toggler-icon">
          <img src="../../assets/edit.svg" style="width: 18px;
        height: 22px;" /> </span>
        </button>
    </td>
      <td height="8" style="width:5%;" class="tds_plus" >
      <button (click)="RemoveItem(k)" class="btn-outline-dark"  style="background-color:salmon" >
         <span class="navbar-toggler-icon">
          <img src="../../assets/delete.svg" style="width: 18px;
        height: 22px;" /> </span></button>
      </td>

    </tr>

  </table>

</div>
</div>

<br>



<div *ngIf="show_hide && this.hs.o_m.varibs.i != -1"  class="container">
  <div class="row">
    <div class="col-sm-3 col-6">
      <button   type="button"   (click)="submit_fresh_order()" class="btn btn-primary btn-lg btn-block">
        Submit
      </button>
    </div>

    <div class="col-sm-3 col-6">
      <button   type="button"   (click)="put_to_hold()" class="btn btn-warning btn-lg btn-block">
        Put to Hold
      </button>
    </div>

 </div>
 <br>
</div>


<!-- <div class="row">

</div> -->




 <!--   product updation modal  -->

<form [formGroup]="updationForm">
  <div id="edit_updation_modal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">



          <h5 class="modal-title">Edit Details</h5>
          <button type="button" (click)="modal_close_edit_details()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>




        </div>



        <div class="modal-body">

          <div class="col-12 col-sm-12">
            <div class="input-group input-group-sm mb-6">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm">
                  Select Brand
                </span>
              </div>

               <select  formControlName="_BRAND_U"   id="bn" class="form-control">
                <option *ngFor="let type of brand_list" [value]="type">{{type}}</option>
              </select>

          </div>

          </div>


  <div class="col-12 col-sm-12">
    <div class="input-group input-group-sm mb-6">
      <div class="input-group-prepend">
        <span class="input-group-text" id="inputGroup-sizing-sm">
          Select size
        </span>
      </div>

       <select   formControlName="_SIZE_U"   id="sz" class="form-control">
        <option *ngFor="let type of size_list" [value]="type">{{type}}</option>
      </select>

  </div>

  </div>



  <div class="col-12 col-sm-12">
    <div class="input-group input-group-sm mb-6">
      <div class="input-group-prepend">
        <span class="input-group-text" id="inputGroup-sizing-sm">
          Select Color
        </span>
      </div>

       <select     formControlName="_COLOR_U"  id="clr" class="form-control">
        <option *ngFor="let type of color_list" [value]="type">{{type}}</option>
      </select>

  </div>

  </div>


  <div class="col-12 col-sm-12">
    <div class="input-group input-group-sm mb-6">
      <div class="input-group-prepend">
        <span class="input-group-text" id="inputGroup-sizing-sm">
          Select Qty
        </span>
      </div>

      <input  formControlName="_QTY_U"
      type="number"
      class="form-control"
      aria-label="Small"
      aria-describedby="inputGroup-sizing-sm"/>

  </div>


  </div>


  <div class="col-12 col-sm-12">
    <div class="input-group input-group-sm mb-6">
      <div class="input-group-prepend">
        <span class="input-group-text" id="inputGroup-sizing-sm">
        Remarks
        </span>
      </div>

      <input  formControlName="_REMARK_U"
      type="text"
      class="form-control"
      aria-label="Small"
      aria-describedby="inputGroup-sizing-sm"/>

  </div>


  </div>




 </div>



        <div class="modal-footer">
          <button type="button"  (click)="modal_close_edit_details()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button [disabled]="!updationForm.valid" type="button" (click)="update_edited_details()" class="btn btn-primary">Update </button>
        </div>


      </div>
    </div>
  </div>
  </form>


 <!--   product updation modal end  -->
