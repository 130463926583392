




  <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="/#/dash">Home</a></li>
    <li class="breadcrumb-item"><a href="/#/dash">Order</a></li>
    <li class="breadcrumb-item active" aria-current="page">View  Orders </li>
  </ol>
</nav>




<div class="container">


<div class="table-responsive-sm">

<ngx-table class="table" [configuration]="configuration"
           [data]="data"
           [columns]="columns"  (event)="eventEmitted($event)" >
</ngx-table>

</div>
</div>












