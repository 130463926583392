
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Router } from '@angular/router';

import { FormGroup, FormControl,Validators } from '@angular/forms';

import { Http_client_Service } from '../../Service/http.service';
@Component({ selector: 'app-main-settings-page',
 templateUrl: './main_page.html',
 styleUrls: ['./main_page.css']
 })
export class Setting_mainpage_Component implements OnInit {



  Brand_modal_3 : any;
  Size_modal_3 : any;
  Color_modal_3 :any;
  Add_product_modal_3 : any;
  Add_user_modal_3 : any;
  delete_color_modal_3 : any;
  delete_size_modal_3 : any;
  delete_brand_modal_3 : any;
  delete_product_modal_3 : any;
  manage_user_modal_3 : any;
  options : any [] = ['User','Shop']

  color_list : any [] = []
  size_list: any [] = []
  brand_list : any [] = []
  product_list : any [] = []
  array_of_users : any [] = [];



  brandForm = new FormGroup({
    BRAND_NAME: new FormControl('', [Validators.required, Validators.minLength(1)])
  });


  sizeForm = new FormGroup({
  SIZE: new FormControl('', [Validators.required, Validators.minLength(1)])
  })


  colorForm = new FormGroup({
    COLOR_LIST : new FormControl('', [Validators.required, Validators.minLength(1)])
  })



  productForm = new FormGroup({

    PRODUCT_NAME: new FormControl('', [Validators.required, Validators.minLength(3)]),

  });





  userForm = new FormGroup({


    USER_NAME: new FormControl('',[Validators.required, Validators.minLength(3)]),
    PASSWORD: new FormControl('',[Validators.required, Validators.minLength(1)]),
    USER_ROLE: new FormControl('',[Validators.required]),


  });





    constructor(private router: Router,private hs:Http_client_Service ) { }

    ngOnInit() {



     this.Brand_modal_3 = document.getElementById("Brand_modal");
     this.Size_modal_3 = document.getElementById("size_modal");
     this.Color_modal_3 = document.getElementById("color_modal");
     this.Add_product_modal_3 = document.getElementById("add_product_modal");
     this.Add_user_modal_3 = document.getElementById("add_user_modal");
     this.delete_color_modal_3 = document.getElementById("delete_color_modal");
     this.delete_size_modal_3 =  document.getElementById("delete_size_modal");
     this.delete_brand_modal_3 =  document.getElementById("delete_brand_modal");
     this.delete_product_modal_3 =  document.getElementById("delete_product_modal");
     this.manage_user_modal_3 =  document.getElementById("manage_user_modal");


     this.hs.is_shop_updation = false;

    }



    change_user_name(key:any)
    {
      console.log('key',key);

      let user : any;
      user = this.array_of_users.filter(xi=> xi.USER_NAME  == key);

      if(user.length == 1)
      {

        this.userForm.patchValue({USER_NAME : key, USER_ROLE : user[0].USER_ROLE , PASSWORD: user[0].PASSWORD })


      }

}

update_user_details()
{
  this.hs.update_user_details(this.userForm.value)
  .subscribe((jsonData:any) => { this._get_res_10(jsonData)
          },(err:any) => console.error(err),

          );

}
_get_res_10(result:any)
{
  console.log(result)
  alert(result.msg);


  while(this.array_of_users.length){
    this.array_of_users.pop();
  }

  this.hs.get_all_users()
        .subscribe((jsonData:any) => { this._get_res_9(jsonData)
                },(err:any) => console.error(err),

                );

}


    modal_close_manage_user()
    {
      this.manage_user_modal_3.style.display="none"
    }


    open_modal_add_brand()
    {
      this.Brand_modal_3.style.display = "block";
    }
    modal_close_brand()
    {
      this.Brand_modal_3.style.display = "none";
    }

    close_modal_delete_product()
    {
      this.delete_product_modal_3.style.display = "none";
    }


    open_modal_add_size()
    {
      this.Size_modal_3.style.display = "block";
    }
    modal_close_size()
    {
      this.Size_modal_3.style.display = "none";
    }



    open_modal_add_color()
    {
      this.Color_modal_3.style.display = "block";
    }
    modal_close_color()
    {
      this.Color_modal_3.style.display = "none";
    }



    Save_brand_name()
    {

       this.hs.add_new_brand(this.brandForm.value)
       .subscribe((jsonData:any) => { this._get_res_2(jsonData)
               },(err:any) => console.error(err),

               );
    }
     Save_size()
     {

        this.hs.add_new_size(this.sizeForm.value)
        .subscribe((jsonData:any) => { this._get_res_2(jsonData)
                },(err:any) => console.error(err),

                );
     }
     save_color()
     {

        this.hs.add_new_color(this.colorForm.value)
        .subscribe((jsonData:any) => { this._get_res_2(jsonData)
                },(err:any) => console.error(err),

                );
     }


    add_product()
    {

      this.Add_product_modal_3.style.display = "block";
    }
    modal_close_add_product()
     {
       this.Add_product_modal_3.style.display = "none";
     }
     save_new_product()
     {

       this.hs.add_new_product(this.productForm.value)
       .subscribe((jsonData:any) => { this._get_res_2(jsonData)
               },(err:any) => console.error(err),

               );
     }
     _get_res_2(json :any)
     {
       alert(json.msg);
       this.productForm.reset();
       this.brandForm.reset();
       this.colorForm.reset();
       this.sizeForm.reset();

     }

     update_user()
     {
        this.manage_user_modal_3.style.display="block";
        this.hs.get_all_users()
        .subscribe((jsonData:any) => { this._get_res_9(jsonData)
                },(err:any) => console.error(err),

                );

     }
     _get_res_9(data:any)
     {
       this.array_of_users = data;
     }

     delete_product()
     {

      this.delete_product_modal_3.style.display = "block";
      this.get_all_products();
     }

     delete_product_from_list()
     {
      this.hs.delete_product(this.productForm.value)
      .subscribe((jsonData:any) => { this._get_res_7(jsonData)
              },(err:any) => console.error(err),

              );
     }

     _get_res_7(data:any)
     {
      alert(data.msg);
      this.productForm.reset();
      while(this.product_list.length){
        this.product_list.pop();
      }

      this.get_all_products();
     }

     get_all_products()
     {
      this.hs.get_all_products()
      .subscribe((jsonData:any) => { this._get_res_6(jsonData)
              },(err:any) => console.error(err),

              );
     }

     _get_res_6(products : any)
     {
      console.log('pdts',products);
      while(this.product_list.length){
        this.product_list.pop();
      }

      for(var j = 0;j<products.length;j++ )
       {
        this.product_list[j] = products[j].PRODUCT_NAME
       }


     }


     open_modal_delete_brand()
      {

          this.delete_brand_modal_3.style.display = "block";
          this.get_all_brands();

      }
      get_all_brands()
      {
        this.hs.get_all_brands()
        .subscribe((jsonData:any) => { this._get_res_5(jsonData)
                },(err:any) => console.error(err),

                );
      }

      _get_res_5(brands : any)
      {
        console.log('size',brands);
        while(this.brand_list.length){
          this.brand_list.pop();
        }

        for(var j = 0;j<brands.length;j++ )
         {
          this.brand_list[j] = brands[j].BRAND_NAME
         }


      }


      close_modal_delete_brand()
    {
      this.delete_brand_modal_3.style.display = "none"
    }

      open_modal_delete_size()
      {
        this.delete_size_modal_3.style.display = "block";
        this.get_all_sizes();
      }
      get_all_sizes()
      {

        this.hs.get_all_size()
        .subscribe((jsonData:any) => { this._get_res_4(jsonData)
                },(err:any) => console.error(err),

                );

      }
      _get_res_4(size : any)
      {
        console.log('size',size);


        while(this.size_list.length){
          this.size_list.pop();
        }


         for(var j = 0;j<size.length;j++ )
         {
          this.size_list[j] = size[j].SIZE
         }

      }


      open_modal_delete_color()
      {
        this.delete_color_modal_3.style.display = "block";

        this.get_all_colors();


      }

      close_modal_delete_Size()
      {
          this.delete_size_modal_3.style.display = "none";
      }


      get_all_colors()
      {
        this.hs.get_all_colors()
        .subscribe((jsonData:any) => { this._get_res_3(jsonData)
                },(err:any) => console.error(err),

                );
      }

      _get_res_3(colors:any)
      {
        console.log('colors',colors);


        while(this.color_list.length){
          this.color_list.pop();
        }


         for(var i = 0;i<colors.length;i++ )
         {
          this.color_list[i] = colors[i].COLOR_LIST
         }


      }

      close_modal_delete_color()
      {
        this.delete_color_modal_3.style.display = "none";
      }

      add_user()
      {
        this.Add_user_modal_3.style.display = "block";
      }
      modal_close_add_user()
        {
          this.Add_user_modal_3.style.display = "none";
        }
        save_new_user()
        {
          console.table(this.userForm.value);


            this.hs.add_new_user(this.userForm.value)
            .subscribe((jsonData:any) => { this._get_res_1(jsonData)
                    },(err:any) => console.error(err),

                    );
       }

       _get_res_1(json :any)
        {
          alert(json.msg);
          this.userForm.reset();

        }



        add_shop()
        {
          this.router.navigate(['/add-shop'])
        }


       delete_color()
       {

        this.hs.delete_color(this.colorForm.value)
        .subscribe((jsonData:any) => { this._get_res_1(jsonData)
                },(err:any) => console.error(err),

                );
       }
       delete_size()
       {
        this.hs.delete_size(this.sizeForm.value)
        .subscribe((jsonData:any) => { this._get_res_1(jsonData)
                },(err:any) => console.error(err),

                );
       }
       delete_brand()
       {

        this.hs.delete_brand(this.brandForm.value)
        .subscribe((jsonData:any) => { this._get_res_1(jsonData)
                },(err:any) => console.error(err),

                );
       }

    update_shop()
    {
      this.hs.is_shop_updation = true;
      this.router.navigate(['/add-shop'])
    }



}
