

import { Component, OnDestroy , OnInit} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
export let browserRefresh = false;
import { NgxPermissionsService } from 'ngx-permissions';
import { Http_client_Service } from './Service/http.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ofos';

  user_role: any = '';
  user_id : any ;

  subscription: Subscription;
  constructor(private router: Router,private ps: NgxPermissionsService,private hs:Http_client_Service) {
    this.subscription = router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          browserRefresh = !router.navigated;
        }
    });

 }
 ngOnInit() {

console.log('hi');


  this.user_role  =   localStorage.getItem('user_role');
  this.user_id  =  localStorage.getItem('user_id');
  this.hs.user_id = this.user_id;
  this.hs.user_role = this.user_role;
  this.ps.loadPermissions([this.user_role]);
 }
 ngOnDestroy() {
  this.subscription.unsubscribe();
}

}
