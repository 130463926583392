<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="/#/dash">Home</a></li>

    <li class="breadcrumb-item active" aria-current="page">settings</li>
  </ol>
</nav>



<div class="container">



<table class="table table-bordered">
  <thead>
    <tr style="background-color: skyblue;">

      <th scope="col">Product</th>
      <th scope="col">User</th>
      <th scope="col">Shops</th>
    </tr>
  </thead>
  <tbody>
    <tr>

      <td> <button type="button" style="width: 100%;font-weight: 500;" (click)="add_product()" class="btn btn-outline-primary">Add Product</button>
        </td>
      <td><button type="button"  style="width: 100%;font-weight: 500;" (click)="add_user()" class="btn btn-outline-primary">Add New User</button></td>
      <td><button type="button"  style="width: 100%;font-weight: 500;" (click)="add_shop()" class="btn btn-outline-primary">Add shop</button></td>
    </tr>

    <tr>

      <td> <button type="button"  style="width: 100%;font-weight: 500;" (click)="delete_product()" class="btn btn-outline-primary"> Delete Product  </button>  </td>
      <td> <button type="button"  style="width: 100%;font-weight: 500;" (click)="update_user()" class="btn btn-outline-primary"> Manage User </button> </td>
      <td> <button type="button"  style="width: 100%;font-weight: 500;" (click)="update_shop()" class="btn btn-outline-primary"> Update shop </button></td>
    </tr>
  </tbody>
</table>




<table class="table table-bordered">
  <thead>
    <tr style="background-color: skyblue;font-weight: 500;">

      <th scope="col">Brand</th>
      <th scope="col">Size</th>
      <th scope="col">Color</th>
    </tr>
  </thead>
  <tbody>
    <tr>

      <td>  <button type="button"  style="width: 100%;font-weight: 500;" (click)="open_modal_add_brand()" class="btn btn-outline-secondary">Add Brand</button>    </td>
      <td>  <button type="button"  style="width: 100%;font-weight: 500;" (click)="open_modal_add_size()" class="btn btn-outline-secondary">Add New Size    </button>   </td>
      <td>  <button type="button"  style="width: 100%;font-weight: 500;" (click)="open_modal_add_color()" class="btn btn-outline-secondary">Add New color</button>   </td>


    </tr>

    <tr>

      <td><button type="button"  style="width: 100%;font-weight: 500;"  (click)="open_modal_delete_brand()"class="btn btn-outline-danger">Delete Brand</button></td>
      <td> <button type="button"  style="width: 100%;font-weight: 500;" (click)="open_modal_delete_size()" class="btn btn-outline-danger">Delete Size</button></td>
      <td> <button type="button"  style="width: 100%;font-weight: 500;" (click)="open_modal_delete_color()" class="btn btn-outline-danger">Delete Color</button></td>
    </tr>
  </tbody>
</table>

</div>


<!--              Manage user modal             -->






<form [formGroup]="userForm">
  <div id="manage_user_modal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Manage user</h5>
          <button type="button" (click)="modal_close_manage_user()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <label for="input_product_1">User Search</label>

          <input #buser (keyup)="change_user_name(buser.value)" (blur)="change_user_name(buser.value)"
           auto-complete formControlName="USER_NAME" list-formatter=" USER_NAME -- USER_ROLE"
          loading-text = "loading"
          display-property-name="USER_NAME" class="form-control" [source]="array_of_users"/>

          <label >Password</label>

          <input formControlName="PASSWORD" type="password" class="form-control" id="pass_code">


          <label for="delete_color">Select Role</label>
          <select  formControlName="USER_ROLE" id="user_role" class="form-control">
            <option  selected>Admin</option>
            <option *ngFor="let type of options" [value]="type">{{type}}</option>
          </select>



        </div>
        <div class="modal-footer">
          <button type="button"  (click)="modal_close_manage_user()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" (click)="update_user_details()"  class="btn btn-primary">Save </button>
        </div>
      </div>
    </div>
  </div>
  </form>


<!--              Manage user modal end             -->




<!--              Add Brand modal             -->

<form [formGroup]="brandForm">
<div id="Brand_modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Brand Name</h5>
        <button type="button" (click)="modal_close_brand()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <input formControlName="BRAND_NAME" type="text" class="form-control" id="input_text_brandname">
      </div>
      <div class="modal-footer">
        <button type="button"  (click)="modal_close_brand()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button  [disabled]="!brandForm.valid" type="button" (click)="Save_brand_name()"  class="btn btn-primary">Save </button>
      </div>
    </div>
  </div>
</div>
</form>

<!--              Add Brand modal   end           -->



<!--              Add Size modal           -->

<form [formGroup]="sizeForm">
<div id="size_modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Size</h5>
        <button type="button" (click)="modal_close_size()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <input formControlName="SIZE"  type="text" class="form-control" id="input_text_size">
      </div>
      <div class="modal-footer">
        <button type="button"  (click)="modal_close_size()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button"(click)="Save_size()" class="btn btn-primary">Save </button>
      </div>
    </div>
  </div>
</div>
</form>


<!--              Add Size modal   end           -->

<!--              Add color modal              -->

<form [formGroup]="colorForm">

<div id="color_modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Color</h5>
        <button type="button" (click)="modal_close_color()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <input formControlName="COLOR_LIST"  type="text" class="form-control" id="input_text_color">
      </div>
      <div class="modal-footer">
        <button type="button"  (click)="modal_close_color()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" (click)="save_color()" class="btn btn-primary">Save </button>
      </div>
    </div>
  </div>
</div>



</form>

<!--              Add color modal   end           -->




<!--              Delete color modal             -->

<form [formGroup]="colorForm">


<div id="delete_color_modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Delete Color</h5>
        <button type="button" (click)="close_modal_delete_color()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">



        <label for="delete_color">Select Color</label>
        <select  formControlName="COLOR_LIST" id="delete_color" class="form-control">

          <option *ngFor="let type of color_list" [value]="type">{{type}}</option>
        </select>


      </div>
      <div class="modal-footer">
        <button type="button"  (click)="close_modal_delete_color()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" (click)="delete_color()" class="btn btn-danger">Delete</button>
      </div>
    </div>
  </div>
</div>
</form>

<!--              Delete color modal  end          -->



<!--              Delete size modal             -->

<form [formGroup]="sizeForm">

<div id="delete_size_modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Delete Size</h5>
        <button type="button" (click)="close_modal_delete_Size()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">



        <label for="delete_size">Select size</label>
        <select  formControlName="SIZE" id="delete_size" class="form-control">
          <option *ngFor="let type of size_list" [value]="type">{{type}}</option>
        </select>


      </div>
      <div class="modal-footer">
        <button type="button"  (click)="close_modal_delete_Size()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" (click)="delete_size()" class="btn btn-danger">Delete</button>
      </div>
    </div>
  </div>
</div>
</form>

<!--              Delete size modal  end           -->



<!--              Delete brand modal             -->

<form [formGroup]="brandForm">
<div id="delete_brand_modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Delete Brand</h5>
        <button type="button" (click)="close_modal_delete_brand()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">



        <label for="delete_brand">Select Brand</label>
        <select  formControlName="BRAND_NAME" id="delete_brand" class="form-control">
          <option *ngFor="let type of brand_list" [value]="type">{{type}}</option>
        </select>


      </div>
      <div class="modal-footer">
        <button type="button"  (click)="close_modal_delete_brand()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" (click)="delete_brand()" class="btn btn-danger">Delete</button>
      </div>
    </div>
  </div>
</div>
</form>


<!--              Delete brand modal  end           -->



<!--              Delete Product modal             -->
<form [formGroup]="productForm">

<div id="delete_product_modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Delete Product</h5>
        <button type="button" (click)="close_modal_delete_product()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">



        <label for="delete_brand">Select Product</label>
        <select  formControlName="PRODUCT_NAME" id="delete_product" class="form-control">
          <option *ngFor="let type of product_list" [value]="type">{{type}}</option>
        </select>


      </div>
      <div class="modal-footer">
        <button type="button"  (click)="close_modal_delete_product()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" (click)="delete_product_from_list()" class="btn btn-danger">Delete</button>
      </div>
    </div>
  </div>
</div>
</form>
<!--              Delete Product modal    end         -->


<!--              Add Product modal           -->
<form [formGroup]="productForm">
<div id="add_product_modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Product Description</h5>
        <button type="button" (click)="modal_close_add_product()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">


        <input formControlName="PRODUCT_NAME" type="text" class="form-control" >
      </div>
      <div class="modal-footer">
        <button type="button"  (click)="modal_close_add_product()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button [disabled]="!productForm.valid" type="button" (click)="save_new_product()" class="btn btn-primary">Save </button>
      </div>
    </div>
  </div>
</div>
</form>






<!--              Add Product modal      end     -->

<!--              Add user modal           -->


<form [formGroup]="userForm">
<div id="add_user_modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add user</h5>
        <button type="button" (click)="modal_close_add_user()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label for="username">User Name</label>


        <input formControlName="USER_NAME" type="text" class="form-control" > <br>
        <label for="password">Password</label>
        <input formControlName="PASSWORD" type="password" class="form-control" > <br>
        <label for="inputState">User Role</label>
        <select  formControlName="USER_ROLE" id="inputState" class="form-control">
          <option  selected>Admin</option>
          <option *ngFor="let type of options" [value]="type">{{type}}</option>
        </select>




      </div>
      <div class="modal-footer">
        <button type="button"  (click)="modal_close_add_user()"  class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button [disabled]="!userForm.valid" type="button" (click)="save_new_user()" class="btn btn-primary">Save </button>
      </div>
    </div>
  </div>
</div>
</form>

<!--              Add user modal      end     -->



