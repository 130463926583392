import { Component, OnInit } from '@angular/core';
import {FormBuilder,FormControl, FormGroup, Validators} from '@angular/forms';
import { Http_client_Service } from '../../Service/http.service';
@Component({
  selector: 'app-add-shop',
  templateUrl: './add-shop.html',
  styleUrls: ['./add-shop.css']
})
export class Add_shopComponent implements OnInit {

  myData : any;
  mySource : any [] = [];
  shopForm = new FormGroup({
    SHOP_NAME: new FormControl('',Validators.required),
    SHOP_GSTIN: new FormControl(''),
    SHOP_LOCATION: new FormControl(''),
    SHOP_PHONE: new FormControl(''),
    SHOP_EMAIL: new FormControl(''),

  });

  shop_updation_Form = new FormGroup({
    SHOP_NAME: new FormControl('',Validators.required),
    SHOP_GSTIN: new FormControl(''),
    SHOP_LOCATION: new FormControl(''),
    SHOP_PHONE: new FormControl(''),
    SHOP_EMAIL: new FormControl(''),
    ID: new FormControl(''),

  });
  constructor(public hs: Http_client_Service ) {


   }




  ngOnInit(): void {

    this.get_all_shop_details();

  }

  get_all_shop_details()
  {
    this.hs.get_all_shop_details()
    .subscribe((jsonData:any) => { this._get_all_shop_details(jsonData)
            },(err:any) => console.error(err),

            );
  }

  _get_all_shop_details(shops:any)
  {
    this.mySource = shops;
  }

  addshop()
  {

    console.log(this.shopForm.value);
    this.hs.add_new_shop(this.shopForm.value)
    .subscribe((jsonData:any) => { this._get_res_2(jsonData)
            },(err:any) => console.error(err),

            );
  }

  delete_shop()
  {

    console.log(this.shop_updation_Form.value);
    this.hs.delete_exsiting_shop(this.shop_updation_Form.value)
    .subscribe((jsonData:any) => { this._get_res_3(jsonData)
            },(err:any) => console.error(err),

            );

  }

  update_shop()
  {

    console.log(this.shop_updation_Form.value);
    this.hs.update_exsiting_shop(this.shop_updation_Form.value)
    .subscribe((jsonData:any) => { this._get_res_3(jsonData)
            },(err:any) => console.error(err),

            );

  }


  _get_res_2(json :any)
  {
    alert(json.msg);
    this.shopForm.reset();

  }

  _get_res_3(json :any)
  {
    alert(json.msg);
    this.shop_updation_Form.reset();

    this.get_all_shop_details();

  }


  change_shop_name(i_d:any)
{
    console.log(i_d)
    let bi : any;
    bi = this.mySource.filter(xi=> xi.ID  == i_d);

      if(bi.length == 1)
      {

        this.shop_updation_Form.patchValue({SHOP_NAME : bi[0].SHOP_NAME , SHOP_LOCATION : bi[0].SHOP_LOCATION ,
           SHOP_GSTIN :   bi[0].SHOP_GSTIN  , SHOP_PHONE :  bi[0].SHOP_PHONE , SHOP_EMAIL: bi[0].SHOP_EMAIL , ID:i_d })


      }

}




 }


