import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { _head,_varibs,_items,order_master } from './model';



@Injectable({ providedIn: 'root' })
export class Http_client_Service {

 //private postUrl = 'http://localhost:4201/api/post/';
 //private getUrl =  'http://localhost:4201/api/get/';

  private getUrl = 'http://ofos.csweb.in/little_missy/api/get/';
  private postUrl =  'http://ofos.csweb.in/little_missy/api/post/';





  is_shop_updation : boolean = false;
  user_id : number = 0;
  user_role : string = '';
  status_flag : number = -2;
  click_status : number = -2;
  order_id : number = 0;
  selected_order :  any [] = [];
  rej_reason : string = '';
  order_person_name : string = '';

    o_h = new _head(0,'','','',new Date(),0,-1,'',0)
    o_v = new _varibs(0,0)
    o_i = new _items(0,'',0,0,'','','','','',0,new Date(),'','')
    o_m = new order_master(this.o_h,[],this.o_v);




  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(  private http: HttpClient ) { }




  login(model:any)
  {
    let body = JSON.stringify(model);
    console.log('body',body);
    return this.http.post(this.postUrl + 'login', body, this.httpOptions).pipe(

      catchError(this.handleError<any>('login',body))
    );
  }

  get_holding_order_list(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_holding_order_list'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_holding_order_list'))
    );
  }


  get_holding_order_count(id:number) :  Observable<any> {

    const url = `${this.getUrl + 'get_holding_order_count'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_holding_order_count'))
    );

  }

  get_active_order_count(id: number): Observable<any> {

    const url = `${this.getUrl + 'get_active_order_count'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_active_order_count'))
    );
  }


  get_despatched_order_count(id:number) :  Observable<any> {

    const url = `${this.getUrl + 'get_despatched_order_count'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_despatched_order_count'))
    );

  }

  get_cancelled_order_count(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_cancelled_order_count'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_cancelled_order_count'))
    );
  }

  get_rejected_order_count(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_rejected_order_count'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_rejected_order_count'))
    );
  }







  get_active_order_count_admin() : Observable<any> {
    const url = `${this.getUrl + 'get_active_order_count_admin'}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_active_order_count_admin'))
    );
    }

    get_despatched_order_count_admin(id: number):  Observable<any> {

      const url = `${this.getUrl + 'get_despatched_order_count_admin'}/${id}`;
      return this.http.get<any>(url ,this.httpOptions).pipe(
        catchError(this.handleError<any>('get_despatched_order_count_admin'))
      );
    }

  get_cancelled_order_count_admin(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_cancelled_order_count_admin'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_cancelled_order_count_admin'))
    );
  }

  get_rejected_order_count_admin(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_rejected_order_count_admin'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('get_rejected_order_count_admin'))
    );
  }


















  reject_order(model:any): Observable<any> {
    let body = JSON.stringify(model);
    console.log('body',body);
    return this.http.post(this.postUrl + 'reject_order', body, this.httpOptions).pipe(

      catchError(this.handleError<any>('reject_order',body))
    );
  }

  cancel_new_order(id: number,user_id:number): Observable<any> {
    const url = `${this.getUrl + 'cancel_new_order'}/${id}/${user_id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('cancel_new_order'))
    );
  }

  execute_order(id: number,user_id:number): Observable<any> {
    const url = `${this.getUrl + 'execute_order'}/${id}/${user_id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('execute_order'))
    );
  }

  revoke_order(id: number,user_id:number): Observable<any> {
    const url = `${this.getUrl + 'revoke_order'}/${id}/${user_id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('revoke_order'))
    );
  }

  submit_holding_order(id: number)
  {
    const url = `${this.getUrl + 'submit_holding_order'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('submit_holding_order'))
    );
  }

  cancel_holding_order(id: number)
  {
    const url = `${this.getUrl + 'cancel_holding_order'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(
      catchError(this.handleError<any>('cancel_holding_order'))
    );
  }


  get_order_detail(model:any)
  {
    let body = JSON.stringify(model);
    console.log('body',body);
    return this.http.post(this.postUrl + 'get_order_detail', body, this.httpOptions).pipe(

      catchError(this.handleError<any>('get_order_detail',body))
    );
  }


  get_order_detail_for_edit(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_order_detail_for_edit'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(

      catchError(this.handleError<any>('get_order_detail_for_edit'))
    );
  }


  get_order_detail_by_id(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_order_detail_by_id'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(

      catchError(this.handleError<any>('get_order_detail_by_id'))
    );
  }

  get_order_person_detail_by_id(id: number): Observable<any> {
    const url = `${this.getUrl + 'get_order_person_detail_by_id'}/${id}`;
    return this.http.get<any>(url ,this.httpOptions).pipe(

      catchError(this.handleError<any>('get_order_person_detail_by_id'))
    );
  }



  get_active_customer_count()
   {
    return this.http.get(this.getUrl + 'get_active_customer_count', this.httpOptions).pipe(

      catchError(this.handleError<any>('get_active_customer_count'))
    );
   }




   get_all_order_list(info:any)
   {

    let body = JSON.stringify(info);
    console.log('body',info);
    return this.http.post(this.getUrl + 'get_all_order_list', body, this.httpOptions).pipe(
      catchError(this.handleError<any>('get_all_order_list',body))
    );



   }







  post_new_order(model: any): Observable<any> {
    let body = JSON.stringify(model);
    console.log('body',body);
    return this.http.post(this.postUrl + 'post_new_order', body, this.httpOptions).pipe(

      catchError(this.handleError<any>('post_new_order',body))
    );

  }


  update_order(model: any): Observable<any> {
    let body = JSON.stringify(model);
    console.log('body',body);
    return this.http.post(this.postUrl + 'update_order', body, this.httpOptions).pipe(

      catchError(this.handleError<any>('update_order',body))
    );

  }

    add_new_user(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'add_new_user', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('add_new_user',body))
      );


    }
    update_user_details(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'update_user_details', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('update_user_details',body))
      );


    }


    get_all_shop_details()
    {
      return this.http.get(this.getUrl + 'get_all_shop_details', this.httpOptions).pipe(

        catchError(this.handleError<any>('get_all_shop_details'))
      );

    }


    add_new_product(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'add_new_product', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('add_new_product',body))
      );


    }


    add_new_shop(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'add_new_shop', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('add_new_shop',body))
      );


    }


    update_exsiting_shop(model :any): Observable<any>
     {
      let body = JSON.stringify(model);
      console.log('body',body)
      return this.http.post(this.postUrl + 'update_exsiting_shop', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('update_exsiting_shop',body))
      );


    }

    delete_exsiting_shop(model :any): Observable<any>
    {
     let body = JSON.stringify(model);
     console.log('body',body)
     return this.http.post(this.postUrl + 'delete_exsiting_shop', body, this.httpOptions).pipe(

       catchError(this.handleError<any>('delete_exsiting_shop',body))
     );


   }


   get_all_products(): Observable<any> {

    return this.http.get(this.getUrl + 'get_all_products', this.httpOptions).pipe(

      catchError(this.handleError<any>('get_all_products'))
    );


  }


    add_new_brand(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'add_new_brand', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('add_new_brand',body))
      );


    }

    add_new_size(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'add_new_size', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('add_new_size',body))
      );


    }
    add_new_color(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'add_new_color', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('add_new_color',body))
      );


    }
    delete_product(model :any): Observable<any> {
      let body = JSON.stringify(model)
      return this.http.post(this.postUrl + 'delete_product', body, this.httpOptions).pipe(

        catchError(this.handleError<any>('delete_product',body))
      );


    }

    get_all_colors(): Observable<any> {

      return this.http.get(this.getUrl + 'get_all_colors', this.httpOptions).pipe(

        catchError(this.handleError<any>('get_all_colors'))
      );
    }
    get_all_size(): Observable<any> {

      return this.http.get(this.getUrl + 'get_all_size', this.httpOptions).pipe(

        catchError(this.handleError<any>('get_all_size'))
      );


    }

    get_all_brands(): Observable<any> {

      return this.http.get(this.getUrl + 'all_brands', this.httpOptions).pipe(

        catchError(this.handleError<any>('all_brands'))
      );


    }




    get_all_holding_orders_list()
    {
     return this.http.get(this.getUrl + 'get_all_holding_orders_list', this.httpOptions).pipe(

       catchError(this.handleError<any>('get_all_holding_orders_list'))
     );
    }






    delete_color(model:any): Observable<any> {
              let body = JSON.stringify(model)
              return this.http.post(this.postUrl + 'delete_color',body, this.httpOptions).pipe(

                catchError(this.handleError<any>('delete_color',body))
              );


             }


             delete_size(model:any): Observable<any> {
              let body = JSON.stringify(model)
              return this.http.post(this.postUrl + 'delete_size',body, this.httpOptions).pipe(

                catchError(this.handleError<any>('delete_size',body))
              );


             }

             delete_brand(model:any): Observable<any> {
              let body = JSON.stringify(model)
              return this.http.post(this.postUrl + 'delete_brand',body, this.httpOptions).pipe(

                catchError(this.handleError<any>('delete_brand',body))
              );


             }

             get_all_users(): Observable<any> {

              return this.http.get(this.getUrl + 'get_all_users', this.httpOptions).pipe(

                catchError(this.handleError<any>('get_all_users'))
              );
            }


  /** GET heroes from the server */
  // getHeroes(): Observable<Hero[]> {
  //   return this.http.get<Hero[]>(this.heroesUrl)
  //     .pipe(
  //       tap(_ => this.log('fetched heroes')),
  //       catchError(this.handleError<Hero[]>('getHeroes', []))
  //     );
  // }

  /** GET hero by id. Return `undefined` when id not found */
  // getHeroNo404<Data>(id: number): Observable<Hero> {
  //   const url = `${this.heroesUrl}/?id=${id}`;
  //   return this.http.get<Hero[]>(url)
  //     .pipe(
  //       map(heroes => heroes[0]), // returns a {0|1} element array
  //       tap(h => {
  //         const outcome = h ? `fetched` : `did not find`;
  //         this.log(`${outcome} hero id=${id}`);
  //       }),
  //       catchError(this.handleError<Hero>(`getHero id=${id}`))
  //     );
  // }

  /** GET hero by id. Will 404 if id not found */
  // getHero(id: number): Observable<Hero> {
  //   const url = `${this.heroesUrl}/${id}`;
  //   return this.http.get<Hero>(url).pipe(
  //     tap(_ => this.log(`fetched hero id=${id}`)),
  //     catchError(this.handleError<Hero>(`getHero id=${id}`))
  //   );
  // }

  /* GET heroes whose name contains search term */
  // searchHeroes(term: string): Observable<Hero[]> {
  //   if (!term.trim()) {
  //     // if not search term, return empty hero array.
  //     return of([]);
  //   }
  //   return this.http.get<Hero[]>(`${this.heroesUrl}/?name=${term}`).pipe(
  //     tap(x => x.length ?
  //        this.log(`found heroes matching "${term}"`) :
  //        this.log(`no heroes matching "${term}"`)),
  //     catchError(this.handleError<Hero[]>('searchHeroes', []))
  //   );
  // }

  //////// Save methods //////////

  /** POST: add a new hero to the server */
  // addHero(hero: Hero): Observable<Hero> {
  //   return this.http.post<Hero>(this.heroesUrl, hero, this.httpOptions).pipe(
  //     tap((newHero: Hero) => this.log(`added hero w/ id=${newHero.id}`)),
  //     catchError(this.handleError<Hero>('addHero'))
  //   );
  // }

  // /** DELETE: delete the hero from the server */
  // deleteHero(hero: Hero | number): Observable<Hero> {
  //   const id = typeof hero === 'number' ? hero : hero.id;
  //   const url = `${this.heroesUrl}/${id}`;

  //   return this.http.delete<Hero>(url, this.httpOptions).pipe(
  //     tap(_ => this.log(`deleted hero id=${id}`)),
  //     catchError(this.handleError<Hero>('deleteHero'))
  //   );
  // }

  /** PUT: update the hero on the server */


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


}


